// import logo from './logo.svg';
import './App.css';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from './Pages/Home/Home';

const commonRoutes = [
  {
    path: `/`,
    element: <Home />
    // redirectTo: <HomePage />
  },
]
function App() {

  return (
    <Router>
        <Routes>
          {commonRoutes && commonRoutes.map((route, index) => (
            <Route
              key={index}
              path={route.path}
              element={route.element}
            />
          ))}
        </Routes>
      </Router>
  );
}

export default App;
