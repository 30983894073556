import React, { useEffect, useRef, useState } from "react";
import Logo from '../../assets/images/Bespeakk-logo.png';
import scan from '../../assets/images/click-to-scan.png';
import { MdOutlineQrCodeScanner } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import QrScanner from "qr-scanner";
import { useNavigate } from 'react-router-dom';
import '../Home/Home.scss';
import { TbBackground } from "react-icons/tb";

const Home = () => {
  const scanner = useRef(null);
  const videoEl = useRef(null);
  const qrBoxEl = useRef(null);
  const [qrOn, setQrOn] = useState(true);
  const navigate = useNavigate();
  const [scannedResult, setScannedResult] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true)
    setTimeout(() => {
      if (videoEl.current) {
        scanner.current = new QrScanner(videoEl.current, onScanSuccess, {
          onDecodeError: onScanFail,
          preferredCamera: "environment",
          highlightScanRegion: true,
          highlightCodeOutline: true,
          overlay: qrBoxEl.current || undefined,
        });
  
        scanner.current
          .start()
          .then(() => setQrOn(true))
          .catch((err) => {
            if (err) setQrOn(false);
          });
      }
    }, 500);
 
  };

  const onScanSuccess = (result) => {
    console.log(result);
    let urlToOpen = result?.data;
    window.open(urlToOpen, '_blank');
    setScannedResult(result?.data);
  };

  const onScanFail = (err) => {
    console.log(err);
  };

  useEffect(() => {
  

    return () => {
      if (scanner.current) {
        scanner.current.stop();
        scanner.current.destroy();
        scanner.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (!qrOn) {
      alert("Camera is blocked or not accessible. Please allow camera in your browser permissions and reload.");
    }
  }, [qrOn]);

  return (
    <div className='bespeakk-home'>
      <div className='qr-code' onClick={handleShow}>
        <MdOutlineQrCodeScanner />
        <div className='scan-img'>
          <img src={scan} alt='scan' />
        </div>
      </div>
      <div className='logo'>
        <img src={Logo} alt='logo' />
      </div>
      <span>Book, order, and get things done!</span>
      <div className="footer">
        <span>Powered By TOPNOTCH</span>
      </div>

      <Offcanvas show={show}   onHide={handleClose} placement="bottom">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className='mode'>
            <img src={Logo} alt='logo' />
          </div>
         
          <div className="QR-code-bg">
          <div className="scan-code">
              <div className="scan-code__angle scan-code__angle--top"></div>

              <video className="scanner" ref={videoEl}></video>
              <div className="scan-code" ref={qrBoxEl} ></div>
              
              <div className="scan-code__angle scan-code__angle--bottom"></div>
            </div>
            
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default Home;
